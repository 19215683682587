import simpleParallax from 'simple-parallax-js'

(function() {
    const servicesBackgroundImage = document.querySelector('.parallax-image')
    startParallax(servicesBackgroundImage)

    function startParallax(image) {
        new simpleParallax(image, {
            scale: 2,
        })
    }
})()